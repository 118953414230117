import { Component } from '@angular/core';
import { MatIconButton, MatButton, MatAnchor } from '@angular/material/button';
import { MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';

@Component({
  // eslint-disable-next-line
  selector: 'modal-content',
  templateUrl: 'modal-content.component.html',
  styleUrls: ['modal-content.component.scss'],
  // eslint-disable-next-line
  host: { class: 'modal-flex-content' },
  standalone: true,
  imports: [
    MatDialogTitle,
    MatIconButton,
    MatDialogClose,
    MatIcon,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatAnchor,
  ],
})
export class ModalContentComponent {}
